.form-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: rgb(183, 169, 171);
  }
  
  .upload-files-container {
	text-align: center;
	width: 400px;
	padding: 20px;
	border: 2px dashed #000000;
	border-radius: 5px;
  }
  
  .drag-file-area {
	margin-bottom: 30px;
  }
  
  .upload-icon {
	font-size: 60px;
	color: #000000;
  }
  
  .dynamic-message {
	font-size: 18px;
	margin-top: 10px;
	font-weight: bold;
	color: #ffffff;
  }
  
  .label {
	display: inline-block;
	margin-top: 10px;
	font-size: 14px;
	color: #ef0606;
	cursor: pointer;
  }
  
  .browse-files {
	color: #fbfeff;
	font-weight: bold;
	margin-left: 5px;
  }
  
  .default-file-input {
	display: none;
  }
  
  .browse-files-text {
	margin-left: 5px;
  }
  
  .cannot-upload-message {
	display: none;
	margin-top: 10px;
	color: #f44336;
	font-size: 14px;
  }
  
  .file-block {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
  }
  
  .file-info {
	flex-grow: 1;
	display: flex;
	align-items: center;
  }
  
  .file-icon {
	font-size: 20px;
	margin-right: 5px;
	color: #338bc5;
  }
  
  .file-name {
	font-size: 14px;
	font-weight: bold;
	color: #000000;
	text-decoration-line: underline;
  }
  
  .file-size {
	font-size: 18px;
	color: #000000;
  }
  
  .remove-file-icon {
	font-size: 18px;
	color: #f44336;
	cursor: pointer;
	margin-right: 1px;
  }
  
  .progress-bar {
	height: 5px;
	background-color: #f44336;
  }
  
  .upload-button {
	display: block;
	margin: 20px auto;
	padding: 10px 20px;
	background-color: #2196f3;
	color: #fff;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	font-size: 16px;
  }
  
  .upload-status {
	font-size: 14px;
	color: #333;
  }
