@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.app {
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

a {
  text-decoration: none !important;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #e3e6ec;
}

@media print {
  html,
  body {
    display: none;
  }
}