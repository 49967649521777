.pay
{
  background-image:url('../../../assets/RVWojO.webp');
  background-repeat: no-repeat;
  background-size: cover;
  min-height:100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.flex-justify {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.gold-prime--assisted-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 1px solid #ccc;
  margin-top: 70px;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  background-color: #c04646;
}
.align{
  display:flex;
  gap: 20px;
}

.gold-prime--assisted-box.selected {
  background-color: #c04646;
}

.box-radio-btn {
  margin-right: 5px;
}

.box-hr,
.box-hr1 {
  width: 100%;
  border: none;
  border-top: 1px solid #000000;
}

.packDuration {
  margin-top: 0;
}

.box-save-text {
  margin-top: 0;
  font-weight: bold;
}

.old-price {
  margin-top: 0;
}

.paid-price {
  margin: 0;
}

.bestSelling {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
}

.beststar1,
.beststar2 {
  width: 20px;
  height: 20px;
}

.box-name-label {
  margin-bottom: 0;
}
.flex-justify {
  display: flex;
  justify-content: space-between;
}

.mt-35 {
  margin-top: 35px;
}

.gold-prime--assisted-box {
  width: 200px;
  padding: 20px;
  border: 1px solid #ccc;
  text-align: center;
}

.selected {
  border-color: #000;
}

.box-radio-btn {
  margin-bottom: 10px;
}

.box-name-label {
  font-weight: bold;
  margin-bottom: 10px;
}

.box-hr {
  margin: 15px 0;
}

.packDuration {
  font-size: 14px;
  margin-bottom: 10px;
}

.box-hr1 {
  margin: 10px 0;
}

.box-save-text {
  font-size: 14px;
  margin: 0;
}

.old-price {
  font-size: 16px;
  margin: 0;
}

.paid-price {
  font-size: 20px;
  margin: 0;
}

.bestSelling {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.beststar1,
.beststar2 {
  width: 16px;
  height: 16px;
  margin: 0 5px;
 
}

.payment-button {
  display: flex;
  margin-top: 20px;
  padding: 15px 100px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.payment-button:hover {
  background-color: #0056b3;
}




.payment-footer {
  border-top: 1px solid #8b2b2b;
  height: 10px;
  width: 100vw;
  border-bottom: 1px solid #8b2b2b;
  background: #000;
  background-image:url("../../../assets/pay-footer-card-icons_new.png");
  background-position: center;
  padding: 40px 0;
  background-repeat: no-repeat;
}

.wrapper-max {
  max-width: 960px;
  margin: 0 auto;
}


.view-all-packages {
  display: flex;
  justify-content: center;
  margin-top: 1px;
  margin-left: 30px;
}

.view-all-link {
  background-color: #c04646;
  color: #ffffff;
  border: none;
  font-size: 14px;
  padding: 7px 10px;
  border-radius: 10px;

  cursor: pointer;
}

.view-all-link:hover {
  background-color: #0056b3;
}
