.card-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 350px;
  padding: 30px;
  border: 1px solid #eb8029;
  border-radius: 5px;
  box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.3);
  background-color: rgb(227, 92, 115);

}

.card-image {
  width: 100%;
  height:150px;
  object-fit: contain;
  border-radius: 5px 5px 0 0;
  
}

.card-content {
  padding-top: 20px;
  width: 100%;
  text-align: center;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
}


.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-top: 2rem;
  gap: 2.5rem;
  color: white;
  width: 100%;
}

.card-list li{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  margin: auto;
}
