.log-btn {
  background-color: rgb(223, 51, 79);
  border: none;
  outline: none;
  cursor: pointer;
}

.reg-login-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 200px;
}

.reg-login-logo-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reg-form-body-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reg-log-from-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.reg-log-from-details a {
  color: white;
  text-decoration: none;
}

.logo-log-img {
  width: 200px;
}

.reg-login-desc {
  font-size: 1.2rem;
  margin-top: 1rem;
}

hr {
  height: 100%;
  width: 5px;
  background-color: red;
}

/* .reg-log-from-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 200px;
  padding: 1rem;
} */

.forgot-pass {
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 12px;
}

.new-user-login {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.error-login {
  font-size: 14px;
  color: red;
}
