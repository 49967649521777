.mailbox-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.mailbox-container .sidenav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 200px;
  background-color: #e22b63;
  padding-top: 20px;
}

.mailbox-container .sidenav button {
  background-color: inherit;
  border: none;
  padding: 10px 10px 10px 20px;
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  color: #f8fafc;
  cursor: pointer;
}

.mailbox-container .sidenav button:hover {
  background-color: #ddd;
  color: #000000;
}

.mailbox-container .sidenav button.active {
  background-color: #ddd;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
}

.inbox-options {
  margin-left: 20px;
  margin-bottom: 10px;
}

.inbox-options button {
  margin-right: 10px;
}

.main {
  flex: 1;
  background-color: #e6e0e0;
  padding: 20px;
  overflow: auto;
}

.mails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
}

.mail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  /* background-color: #c42a41; */
  background-color: #f8fafc;
  color: #000000;
  font-weight: 500;
}

.mail .from {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-right: 10px;
}

.mail .subject {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-right: 10px;
}

.mail .date {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.mail .to {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-right: 10px;
}

.chat-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
}

.chat {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f2f2f2;
}

.chat .profile-pic {
  width: 40px;
  height: 40px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 10px;
}

.chat .chat-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chat .name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}

.chat .last-message {
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.chat .timestamp {
  font-size: 14px;
  font-weight: 400;
  color: #999;
  margin-left: auto;
}

.member-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
}

.member {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.member .profile-pic {
  width: 40px;
  height: 40px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 10px;
}

.member .name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-right: 10px;
}

.member .role {
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.member .status {
  font-size: 14px;
  font-weight: 400;
  color: #999;
  margin-left: auto;
}

.mailbox-container .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.mailbox-container .form input[type="text"],
.mailbox-container .form input[type="email"],
.mailbox-container .form input[type="password"],
.mailbox-container .form textarea {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  width: 100%;
  font-size: 16px;
}

.mailbox-container .form input[type="submit"] {
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.mailbox-container .form input[type="submit"]:hover {
  background-color: #555;
}

.profile-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  width: 500px;
  padding: 20px;
  border-radius: 5px;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-pic {
  width: 50px;
  height: 50px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 20px;
}

.name {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.online,
.offline {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 10px;
}

.online {
  background-color: green;
}

.offline {
  background-color: red;
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.detail {
  display: flex;
  margin-bottom: 10px;
}

.label {
  font-size: 16px;
  font-weight: 500;
  color: #666;
  margin-right: 10px;
}

.value {
  font-size: 16px;
  font-weight: 400;
  color: #333;
}

.close-button {
  position: absolute;
  top: 13px;
  right: 30px;
  font-size: 24px;
  font-weight: 600;
  color: #ff0000;
  cursor: pointer;
}

.close-button:hover {
  color: #333;
}

.mailbox-req-link {
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
}

.mailbox-req {
  font-weight: bold;
  color: #c42a41;
  font-size: 1.1rem;
}

.no-users-message {
  font-size: 32px;
  color: #534f4f;
  margin: 20px 0;
}

@media screen and (max-width: 768px) {
  .mailbox-container {
    flex-direction: column;
    height: auto;
  }

  .mailbox-container .sidenav {
    text-align: center;
    width: 100%;
  }
}
