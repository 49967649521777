.filter-container {
  margin-bottom: 20px;
}

/* CSS for the filter input field */
.filter-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}



/* Optional: Add some margin to the top of the table */
.dashboard-table {
  margin-top: 20px;
}



.dashboard-container {
    margin: 20px;
    margin-left: 100px;
    overflow: auto;
  }
  
  .dashboard-heading {
    font-size: 36px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #e22b63;
    text-align: center;
    font-weight: 600;
  }
  
  .dashboard-table {
    width: 100%;
    border-collapse: collapse;
  }

  
  .dashboard-table th,
  .dashboard-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ad2727;
    text-align: center;
  }
  
  .dashboard-table th {
    font-weight: bold;
    background-color: #b63333;
    color: #ffffff;
    border: 1px solid #000000;
  }
  
  .dashboard-table td {
    background-color: whitesmoke;
    /* border: 1px solid #d7d3d8; */
    border: 1px solid #000;
    border-top: 1px solid #ad2727;
  }
  
  .action-btn {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    background-color: #c44b4b;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .action-btn:hover {
    background-color: #991e1e;
    text-decoration: none;
    color: #fff;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    border: none;
    background-color: #eaeaea;
    color: #333;
    padding: 8px 12px;
    margin: 0 4px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 20px;
  }
  
  .pagination button.active {
    background-color: #333;
    color: #fff;
  }
  