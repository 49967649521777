/* footer.css */

/* Footer container */
footer {
  background-color: #000000;
  color: #bfc2c5;
  padding: 80px 0;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 30px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Footer column */
.foot {
  flex: 1;
  max-width: 25%;
  padding: 0 30px;
}

h3 {
  font-size: 32px;
  margin-bottom: 40px;
  letter-spacing: 1px;
}

p {
  font-size: 20px;
  line-height: 1.8;
}

/* Social media icons */
.list-inline {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 1rem;
}

.list-inline-item {
  margin-right: 30px;
}

.list-inline a {
  color: #bfc2c5;
  font-size: 30px;
  transition: color 0.3s ease;
}

.list-inline a:hover {
  color: #e22b63;
}

/* Copyright section */
.row.mb-4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 30px; /* Adjust padding here */
}

.left {
  text-align: left;
}

.left.text-white.col-md-6.text-center p {
  text-align: left;
  margin: 0;
  color: #bfc2c5;
}

.rig {
  text-align: right;
}
.rig.text-white.col-md-6 p {
  color: #bfc2c5;

}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .foot {
    max-width: 48%;
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .foot {
    max-width: 100%;
    margin-bottom: 50px;
  }

  .row {
    flex-direction: column;
  }

  .foot {
    padding: 0;
    text-align: center;
  }
  .list-inline {
    justify-content: center;
  }
  .row.mb-4 {
    justify-content: center;
  }
  .left, .rig {
    text-align: center;
  }
}
