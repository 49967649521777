.color {
  width: 100dvw;
  transition: margin-left 0.3s ease;
  overflow: auto !important;
}

.open {
  width: 100%;
  overflow: auto !important;
}

.open .color {
  margin-left: 250px;
  overflow: auto !important;
}

.header {
  background-color: #f2f2f2;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  height: 80px;
}

.header__logo {
  font-weight: bold;
}

.page {
  width: 100%;
  height: 100%;
}

.content {
  margin-left: 26px;
  transition: transform 0.3s ease;
  overflow-y: auto !important;
}

.content--open {
  transform: translateX(250px);
  overflow: auto !important;
}

.sidebar {
  position: absolute;
  top: 5rem;
  width: 50px;
  height: 100%;
  background-color: #2a363b;
  transition: width 0.3s ease;
  border-right: 1px #4d606e solid;
  padding-top: 15px;
  z-index: 999;
}

.sidebar .sidebar-position {
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 12px;
  text-align: left;
}

.sidebar .sidebar-position:hover {
  background-color: #3f5159;
  border-right: 5px #e84a5f solid;
}

.sidebar svg {
  color: #e84a5f;
  min-width: 25px;
}

.sidebar span {
  width: 0;
  height: 0;
  padding: 0;
  transition: width 0.3s ease;
  color: #c9c9c9;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar--open {
  width: 250px;
  padding: 0 15px;
  transition: width 0.3s ease;
  box-sizing: content-box !important;
}

.sidebar--open .sidebar-position span {
  display: block;
  width: 100%;
  height: auto;
}

.trigger {
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px 15px;
}
/* Add a media query for screens with a width less than or equal to 768px */
@media (max-width: 768px) {
  .sidebar {
    width: 100px;
  }

  .sidebar--open {
    width: 250px;
  }

  .sidebar .sidebar-position span {
    display: none;
  }

  .trigger {
    padding-right: 10px;
  }

  .trigger svg {
    font-size: 20px;
  }
}

/* Add a media query for screens with a width greater than 768px */
@media (min-width: 769px) {
  .sidebar {
    width: 50px;
  }

  .sidebar--open {
    width: 250px;
  }

  .sidebar .sidebar-position span {
    display: block;
    width: 100%;
    height: auto;
  }

  .trigger {
    padding-right: 12px;
  }

  .trigger svg {
    font-size: 24px;
  }
}
