.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  font-family: sans-serif;
  background: linear-gradient(to bottom, #c03535, #7e3131);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin-left: 25%;
  margin-bottom: 40px;
  width: 50%;
}

.text h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.text input {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 1.2rem;
  width: 100%;
  max-width: 300px;
  transition: box-shadow 0.3s ease-in-out;
}

.text input:focus {
  outline: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
}
