.custom-navbar {
  z-index: 99;
  width: 100%;
  padding: 15px 0;
  box-shadow: -1px 1px 40px -18px rgba(0, 0, 0, 0.5);
}

.custom-navbar .custom-wrapper {
  position: relative;
  padding: 0px 30px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.custom-wrapper .custom-logo {
  width: 250px;
  height: 65px;
  cursor: pointer;
  text-decoration: none;
}

.custom-wrapper .custom-nav-links {
  display: inline-flex;
  padding-top: 15px;
}

.custom-nav-links li {
  list-style: none;
}

.custom-nav-links li a {
  color: #3a3b3c;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.custom-nav-links li a:hover {
  color: #f2f2f2;
  background: #3a3b3c;
}

#bg-prof-hov:hover {
  background: none !important;
}

.custom-nav-links .custom-mobile-item {
  display: none;
}

.custom-nav-links .custom-drop-menu {
  position: absolute;
  background: #f8fafc;
  width: auto;
  line-height: 40px;
  top: 85px;
  opacity: 0;
  padding-right: 10px;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.custom-nav-links .custom-drop-menu-prof {
  position: absolute;
  background: #f8fafc;
  width: auto;
  line-height: 40px;
  top: 85px;
  right: 10px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  padding-right: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.custom-drop-menu li a {
  width: auto;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  font-size: 18px;
  border-radius: 5px;
}

.custom-drop-menu-prof li a {
  font-size: 18px;
  font-weight: 400;
}

.custom-nav-links li:hover .custom-drop-menu,
.custom-nav-links li:hover .custom-drop-menu-prof {
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
  width: auto;
}

.custom-mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.custom-mega-box .custom-content {
  background: #242526;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.custom-mega-box .custom-content .custom-row {
  width: calc(25% - 30px);
  line-height: 45px;
}

.custom-content .custom-row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-content .custom-row header {
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}

.custom-content .custom-row .custom-mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.custom-row .custom-mega-links li {
  padding: 0 20px;
}

.custom-row .custom-mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 17px;
  display: block;
}

.custom-row .custom-mega-links li a:hover {
  color: #f2f2f2;
}

.custom-wrapper .custom-btn {
  color: #3a3b3c;
  font-size: 30px;
  cursor: pointer;
  display: none;
}

.custom-wrapper .custom-btn.custom-close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
  color: #f8fafc;
}

.logout-btn {
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 0px 15px;
  color: rgb(255, 0, 0);
  background-color: transparent;
}

@media screen and (max-width: 970px) {
  .custom-wrapper .custom-btn {
    display: block;
  }
  .custom-wrapper .custom-nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #242526;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }

  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #d5b1b1;
  }

  #menu-btn:checked ~ .custom-nav-links {
    left: 0%;
  }

  #menu-btn:checked ~ .custom-btn.custom-menu-btn {
    display: none;
  }

  #close-btn:checked ~ .custom-btn.custom-menu-btn {
    display: block;
  }

  .custom-nav-links li {
    margin: 15px 10px;
  }

  .custom-nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
    color: #f8fafc;
  }

  .custom-nav-links .custom-drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }

  .custom-nav-links .custom-drop-menu-prof {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 10px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }

  #showDropMatches:checked ~ .custom-drop-menu,
  #showDropProf:checked ~ .custom-drop-menu-prof {
    width: auto;
    max-height: 100%;
  }

  .custom-nav-links .custom-desktop-item {
    display: none;
  }

  .custom-nav-links .custom-mobile-item {
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .custom-nav-links .custom-mobile-item:hover {
    background: #3a3b3c;
  }

  .custom-drop-menu li {
    margin: 0;
  }

  .custom-drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
    color: #3a3b3c;
  }

  .custom-drop-menu-prof li a {
    line-height: 30px;
    font-size: 18px;
    border-radius: 5px;
    color: #3a3b3c;
  }

  .custom-mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .custom-mega-box .custom-content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }

  .custom-mega-box .custom-content .custom-row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }

  .custom-mega-box .custom-content .custom-row:nth-child(1),
  .custom-mega-box .custom-content .custom-row:nth-child(2) {
    border-top: 0px;
  }

  .custom-content .custom-row .custom-mega-links {
    border-left: 0px;
    padding-left: 15px;
  }

  .custom-row .custom-mega-links li {
    margin: 0;
  }

  .custom-content .custom-row header {
    font-size: 19px;
  }
}

.custom-navbar input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}

.body-text div {
  font-size: 45px;
  font-weight: 600;
}
