@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.home-cont {
  padding: 2rem 3rem;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.verify {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../../assets/ver-bg.jpg");
  height: 100vh;
  max-width: 100vw;
  background-size: cover;
  background-position: center;
}

.verify-cont {
  backdrop-filter: blur(10px);
  border-radius: 5px;
  height: auto;
  text-align: center;
  line-height: 45px;
  padding: 2rem;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.5);
  color: wheat;
}

.verify-cont-heading {
  font-size: 4rem;
  font-weight: 400;
  margin-top: 20px;
}

.verify-cont-heading span {
  background-image: linear-gradient(to left, #49d55b, #e22b63, #49d55b);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 500;
}

.verify-cont-wel {
  font-size: 1.5rem;
  font-weight: 400;
}

.verify-cont-wel span {
  background-image: linear-gradient(#49d55b, #e22b63, #49d55b);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 500;
  font-size: 2rem;
}

.verify-cont-ver {
  font-size: 1.1rem;
  font-weight: 500;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* HomeProfile */

.homeprof-cont {
  width: 25%;
  border: 1px solid gray;
  border-radius: 10px;
  height: fit-content;
}

.homeprof-profile-cont {
  background-image: linear-gradient(180deg, #49d55b, #e22b63, #49d55b);
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
}

.homeprof-profile-top {
  background-color: #f5fbf8;
  border-radius: 10px;
  padding: 1rem 0;
}

.homeprof-profile {
  text-align: center;
  position: relative;
}

.homeprof-username {
  line-height: 0px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1.2rem 0;
}

.homeprof-profile-progress {
  line-height: 0px;
  text-align: center;
  font-size: 0.8rem;
}

.complete-profile-link {
  color: #ff9800;
}

.homeprof-profile-bottom {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.homeprof-profile-bottom-desc {
  font-size: 0.95rem;
  color: #fff;
}

.upgrade-link {
  color: #fff;
  background-color: #ff9800;
  border-radius: 50px;
  padding: 0.5rem 0;
  font-size: 0.85rem;
}

.homeprof-settings {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 1rem;
}

.homeprof-setting-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
  gap: 0.5rem;
}

.homeprof-setting-item {
  font-size: 0.9rem;
  font-weight: 500;
}

.mod {
  font-size: 18px;
  text-decoration: none;
}

/* HomeProfile End */

.home-body-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: 3rem;
  width: 75%;
}

/* HomeDailyRecom */

.prof-daily-recom-container {
  border: 1px solid grey;
  padding: 1.5rem 1.5rem 0 1.5rem;
  border-radius: 10px;
}

.prof-daily-recom-heading {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.prof-daily-recom-lists {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  gap: 1rem;
  width: 95%;
  margin: auto;
}

.prof-daily-recom-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  margin: 0.5rem;
}

.prof-daily-recom-img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: top;
}

.prof-daily-recom-name {
  color: #000;
  font-size: 18px;
  text-decoration: none;
}

/* Profile Total Matches  */

.prof-total-matches-heading-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.prof-total-matches-heading-content {
  line-height: 0px;
  margin-bottom: 1rem;
  width: 100%;
}

.prof-total-matches-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-total-matches-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../assets/total.webp");
  height: auto;
  width: 100%;
  max-width: 900px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.profile-total-match-blur {
  width: 100%;
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.prof-total-match-cont {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 4rem;
  color: #fff;
}

.prof-total-matches-img {
  border-radius: 10px;
  width: 100%;
  max-width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: top;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.prof-total-match-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* height: 200px; */
  line-height: 20px;
}

.prof-total-match-details p {
  width: 100%;
  white-space: initial;
  text-overflow: ellipsis;
}

.total-match-view-prof-link {
  color: #fff;
}

.match-intrst-cont-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 2rem;
}

.match-intrst-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: transparent;
}

.intrst-icon {
  font-size: 35px;
  color: #fff;
}

@media screen and (max-width: 1000px) {
  .prof-daily-recom-container {
    width: 100%;
  }

  .prof-total-matches-heading-cont {
    display: flex;
    flex-wrap: wrap;
  }

  .prof-total-matches-heading-content {
    display: flex;
    flex-direction: column;
  }
  .profile-total-matches-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  .profile-total-match-blur {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .prof-total-match-cont {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }
  .home-cont {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 95%;
    margin: auto;
    padding: 2rem 0;
  }
  .homeprof-cont {
    width: 100%;
  }
  .home-body-container {
    width: 100%;
    padding-left: 0;
  }
}

@media screen and (max-width: 568px) {
  .prof-daily-recom-lists {
    justify-content: center;
  }
  .match-intrst-cont-btns {
    margin-top: 20px;
  }
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: top; */
}

.image-container {
  width: 200px;
  height: 225px;
}

.edit-icon-label {
  cursor: pointer;
  color: #f8fafc;
  position: absolute;
  top: 0px;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 4px;
  border-radius: 4px;
}

.edit-icon-image {
  font-size: 20px;
}

.mySwiper {
  z-index: 0 !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev {
  left: 0px;
}

.swiper-button-next {
  right: 0px;
}
