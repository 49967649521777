/* FPass CSS */
.up {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: whitesmoke;
}

.up1 {
  background-color: white;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin: 0 auto;
}

.input-container {
  margin-bottom: 15px;
}
.input-container ::placeholder {
  padding-left: 10px;
}

.up1 label {
  font-weight: bold;
  margin-bottom: 5px;

  display: block;
}

.up1 input {
  width: 100%;
  padding: 10px 2px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.up1 button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
.alert {
  color: red;
  margin-top: 5px;
}

@media screen and (max-width: 480px) {
  .up1 {
    width: 80%;
  }
}

/* Fotp css */
.otp-form-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../images/good.jpg");
  }
  
  .otp-form-container {
    position: relative;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    animation: slideIn 0.5s ease-in-out forwards;
    transform: translateX(-100%);
  }
  
  .otp-form-container h2 {
    margin-bottom: 20px;
  }
  
  .otp-input-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .otp-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: all 8.3s ease-in-out;
  }
  
  .otp-btn:hover {
    background-color: #0069d9;
  }
  
  .otp-resend-btn {
    text-align: left;
    width: 100%;
    background-color: transparent;
    color: #000000;
    border: none;
    outline: none;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: all 8.3s ease-in-out;
  }
  
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

/* Femail css */
.ver-otp-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7fafc;
}

.ver-otp-cont img {
    width: 90%;
    max-width: 400px;
    object-fit: contain;
}


.otp-form-cont {
    width: auto;
    height: auto;
  }
  
  .form-otp-wrapper {
    width: 95%;
    max-width: 500px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-otp-container {
    text-align: center;
  }
  
  .form-otp-container .otp-heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-otp-container .otp-input {
    width: 100%;
    max-width: 300px;
    padding: 12px;
    border: 1px solid #d2d6dc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s;
  }
  
  .form-otp-container .otp-input:focus {
    outline: none;
    border-color: #3182ce;
  }
  
  /* Style the OTP form submit button */
  .form-otp-container .otp-submit-btn {
    width: 100%;
    margin-top: 20px;
    background-color: #3182ce;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .form-otp-container .otp-submit-btn:hover {
    background-color: #2c5282;
  }
  
@media screen and (max-width: 768px){
    .ver-otp-cont {
        display: flex;
        flex-direction: column;
    }
}