.abc .form-control {
  width: 100%;
}

.abc .row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.abc .row-space {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.abc .col-2 {
  width: -webkit-calc((100% - 60px)/2);
  width: -moz-calc((100% - 60px)/2);
  width: calc((100% - 32px) / 2);
}

@media (max-width: 767px) {
  .abc .col-2 {
    width: 100%;
  }
}

.abc html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.abc * {
  padding: 0;
  margin: 0;
}

.abc *,
.abc *:before,
.abc *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

.abc body,
.abc h1,
.abc h2,
.abc h3,
.abc h4,
.abc h5,
.abc h6,
.abc blockquote,
.abc p,
.abc pre,
.abc dl,
.abc dd,
.abc ol,
.abc ul,
.abc figure,
.abc hr,
.abc fieldset,
.abc legend {
  margin: 0;
  padding: 0;
}

.abc li > ol,
.abc li > ul {
  margin-bottom: 0;
}

.abc table {
  border-collapse: collapse;
  border-spacing: 0;
}

.abc fieldset {
  min-width: 0;
  border: 0;
}

.abc button {
  outline: none;
  border: none;
}

.abc .page-wrapper {
  min-height: 100vh;
}

.abc body {
  font-family: roboto, arial, helvetica neue, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.abc h1,
.abc h2,
.abc h3,
.abc h4,
.abc h5,
.abc h6 {
  font-weight: 400;
}

.abc h1 {
  font-size: 36px;
}

.abc h2 {
  font-size: 30px;
}

.abc h3 {
  font-size: 24px;
}

.abc h4 {
  font-size: 18px;
}

.abc h5 {
  font-size: 15px;
}

.abc h6 {
  font-size: 13px;
}

/* .abc .bg-blue {
  background: #2c6ed5
} */

.abc {
  background-image: url(../../assets/88.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.abc .p-t-100 {
  padding-top: 100px;
}

.abc .p-t-180 {
  padding-top: 100px;
}

.abc .p-t-20 {
  padding-top: 20px;
}

.abc .p-t-30 {
  padding-top: 30px;
}

.abc .p-b-100 {
  padding-bottom: 100px;
}

.abc .wrapper {
  margin: 0 auto;
}

.abc .wrapper--w960 {
  max-width: 960px;
  height: auto;
}

.abc .wrapper--w680 {
  max-width: 680px;
}

.abc .btn {
  line-height: 40px;
  display: inline-block;
  padding: 0 25px;
  cursor: pointer;
  color: #fff;
  font-family: roboto, arial, helvetica neue, sans-serif;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 14px;
  font-weight: 700;
}

.abc .btn--radius {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.h {
  color: rgb(227, 54, 83);
}
.abc .btn--green {
  background: #57b846;
}

.abc .btn--green:hover {
  background: #4dae3c;
}

.abc td.active {
  background-color: #2c6ed5;
}

.abc input[type="date"] {
  padding: 14px;
}

.abc .table-condensed td,
.abc .table-condensed th {
  font-size: 14px;
  font-family: roboto, arial, helvetica neue, sans-serif;
  font-weight: 400;
}

.abc .daterangepicker td {
  width: 40px;
  height: 30px;
}

.abc .daterangepicker {
  border: none;
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  display: none;
  border: 1px solid #e0e0e0;
  margin-top: 5px;
}

.abc .daterangepicker::after,
.abc .daterangepicker::before {
  display: none;
}

.abc .daterangepicker thead tr th {
  padding: 10px 0;
}

.abc .daterangepicker .table-condensed th select {
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px;
  outline: none;
}

.abc input {
  outline: none;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 100%;
  font-size: 14px;
  font-family: inherit;
}

.row .row-space {
  margin: 0;
  padding: 0;
}
.abc .input-group {
  position: relative;
  margin-bottom: 32px;
  border-bottom: 1px solid #e5e5e5;
  padding-left: 0px;
}
.abc .input-group1 select {
  width: 100%;
}
.abc .input-group1 {
  position: relative;
  margin-bottom: 32px;
  /* padding: 20px; */
}

.abc .input-icon {
  position: absolute;
  font-size: 18px;
  color: #ccc;
  right: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

.abc .input--style-2 {
  /* padding: 6px 10px; */
  padding-right: 30px;
  color: #666;
  font-size: 16px;
  font-weight: 500;
}

.abc .input--style-2::-webkit-input-placeholder {
  color: gray;
}

.abc .input--style-2:-moz-placeholder {
  color: gray;
  opacity: 1;
}

.abc .input--style-2::-moz-placeholder {
  color: gray;
  opacity: 1;
}

.abc .input--style-2:-ms-input-placeholder {
  color: gray;
}

.abc .input--style-2:-ms-input-placeholder {
  color: gray;
}

.abc .select--no-search .select2-search {
  display: none !important;
}

.abc .rs-select2 .select2-container {
  width: 100% !important;
  outline: none;
}

.abc .rs-select2 .select2-container .select2-selection--single {
  outline: none;
  border: none;
  height: 36px;
}

.abc
  .rs-select2
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 36px;
  padding-left: 0;
  color: gray;
  font-size: 16px;
  font-family: inherit;
  font-weight: 500;
}

.abc
  .rs-select2
  .select2-container
  .select2-selection--single
  .select2-selection__arrow {
  height: 34px;
  right: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.abc
  .rs-select2
  .select2-container
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}

.abc
  .rs-select2
  .select2-container
  .select2-selection--single
  .select2-selection__arrow:after {
  font-family: material-design-iconic-font;
  content: "\f2f9";
  font-size: 18px;
  color: #ccc;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.abc
  .rs-select2
  .select2-container.select2-container--open
  .select2-selection--single
  .select2-selection__arrow::after {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.abc .select2-container--open .select2-dropdown--below {
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #e0e0e0;
  margin-top: 5px;
  overflow: hidden;
}

.abc .title {
  text-transform: uppercase;
  font-weight: 700;
}

.abc .req-des {
  color: red;
  font-size: 13px;
}

.abc form {
  margin-top: 20px;
}

.abc .card {
  /* overflow: hidden; */
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #fff;
}

.abc .card-2 {
  margin-top: 20px;
  height: 100%;
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 50%;
  /* display: table */
}
/* 
.abc .card-2 .card-heading {
  background: url("../images/images.jfif") top left/cover no-repeat;
  object-fit: cover;
  background-size: 100%;
  width: 39.1%;
  text-align: center;
  display: table-cell
} */

.abc .card-2 .card-body {
  /* display: table-cell; */
  padding: 50px;
  height: 100%;
}

@media (max-width: 767px) {
  .abc .card-2 {
    display: block;
    width: 100%;
  }

  .abc .card-2 .card-heading {
    width: 100%;
    display: block;
    padding-top: 500px;
    background-position: left center;
  }

  .abc .card-2 .card-body {
    display: block;
    padding: 60px 50px;
  }
}

.form-group {
  margin-bottom: 1rem;
  margin-left: 1.5rem;
}

.form-check {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.form-check-input {
  width: 1rem !important;
  height: 1rem !important;
  margin-right: 0.5rem !important;
  visibility: visible !important;
  border: 1.5px solid #ced4da !important;
}

.form-check-label {
  font-weight: normal;
}
