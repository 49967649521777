.back {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.horoscope-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 0px 0 0px 0px;
  background-color: rgb(177, 74, 74);
  min-height: 1100px;
  overflow: hidden;
}
.horo-img {
  position: relative;
}
.horoscope-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.horoscope-box {
  width: calc(20% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 24px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 1px;
}

.horoscope-box input {
  background-color: transparent;
  text-align: center;
}

.horoscope-box p {
  background-color: transparent;
  text-align: center;
  width: 200px;
}

.i img {
  position: inherit;
  border: 1px solid rgb(0, 0, 0);
  top: 50.20%;
  left: 47.6%;
  transform: translate(-50%,-50%);
  object-fit: cover;
}

.horoscope-box:nth-child(6),
.horoscope-box:nth-child(7),
.horoscope-box:nth-child(10),
.horoscope-box:nth-child(11) {
  visibility: hidden;
  background-color: #333;
  pointer-events: none;
}

/* Control styles */
.react-select__control {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  height: 50px;
  padding: 0 10px;
}

/* Option styles */
.react-select__option {
  color: #333;
  font-size: 16px;
  padding: 10px;
  overflow: hidden;
}

/* Hover styles */
.react-select__option:hover {
  background-color: #6b1f1f;
}

/* Selected option styles */
.react-select__option--is-selected {
  background-color: #0080ff;
  color: #fff;
}

/* Placeholder styles */
.react-select__placeholder {
  color: #999;
}

/* Dropdown indicator styles */
.react-select__dropdown-indicator {
  color: #999;
}

/* Clear indicator styles */
.react-select__clear-indicator {
  color: #999;
}

@media (max-width: 768px) {
  .horoscope-box {
    width: calc(50% - 10px);
  }
}

.horoscope-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 0px 0 0px 0px;
  background-color: rgb(177, 74, 74);
  height: 100vh;
}

.horoscope-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.horoscope-box {
  width: calc(25% - 10px);
  min-height: 120px; /* Set a minimum height */
  max-height: 160px;

  display: flex;
  flex-direction: column; /* Update to a vertical layout */
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 14px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 1px;
}

.gopi {
  position: relative;
}
.gopi {
  position: absolute;
  border: 1px solid rgb(0, 0, 0);
  top: 50.1%;
  left: 43%;
  transform: translate(-50%, -50%);
  max-width: 100px;
  max-height: 241.9px;
}

.gopi2 {
  position: relative;
}
.gopi2 {
  position: absolute;
  border: 1px solid rgb(0, 0, 0);
  top: 50.1%;
  left: 43%;
  transform: translate(-50%, -50%);
  max-width: 100px;
  max-height: 241.9px;
}
.horoscope-box:nth-child(6),
.horoscope-box:nth-child(7),
.horoscope-box:nth-child(10),
.horoscope-box:nth-child(11) {
  visibility: hidden;
  background-color: #333;
  pointer-events: none;
}

.nextBtn {
  display: flex;
  flex-wrap: wrap;
}

.ga {
  background-color: rgb(177, 74, 74);
  text-align: center;
}
.ag {
  color: white;
  text-decoration: none;
  padding: 30px;
  border: none;
  border-radius: 5px;
}

/* Control styles */
.react-select__control {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  height: 50px;
  padding: 0 10px;
}

/* Option styles */
.react-select__option {
  color: #333;
  font-size: 16px;
  padding: 10px;
}

/* Hover styles */
.react-select__option:hover {
  background-color: #6b1f1f;
}

/* Selected option styles */
.react-select__option--is-selected {
  background-color: #0080ff;
  color: #fff;
}

/* Placeholder styles */
.react-select__placeholder {
  color: #999;
}

/* Dropdown indicator styles */
.react-select__dropdown-indicator {
  color: #999;
}

.react-select__clear-indicator {
  color: #999;
}

/* @media (max-width: 508px) {
    .horoscope-box {
      width: calc(50% - 10px);
    }
  } */
