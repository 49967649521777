.add-kulam-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: antiquewhite;
  padding: 1rem;
}

.kulam-inp {
  border: 1px solid #000;
  width: 100%;
  max-width: 350px;
  padding: 0.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
}

.add-kulam-cont button {
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  outline: none;
  color: #e22b63;
  background-color: whitesmoke;
  border-radius: 5px;
}

.add-kulam-cont button:hover {
  color: #000;
  background-color: #e22b63;
}

.edit-kulam-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cross-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 30px;
  color: red;
}
