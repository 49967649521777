.search-main-cont {
  display: flex;
  align-items: flex-start;
}
.search-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(227, 92, 115);
  border-radius: 5px;
  padding: 0.5rem;
}

.man {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(227, 92, 115);
  min-height: 100%;
  width: 100%;
  max-width: 300px;
}
.search-group {
  margin-right: 20px;
  margin-top: 20px;
}
.search-group label {
  color: black;
}

.search-dropdown select {
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: black;
  font-size: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.search-btn-container {
  align-self: center;
  background-color: rgb(227, 92, 115);
}
.search-btn-container .btnText {
  font-size: 18px;
  font-weight: 400;
  color: white;
  text-decoration: none;
  padding: 10px;
  border-radius: 15px;
}

.no-user {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 700px) {
  .search-main-cont { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .man {
    width: 90%;
    max-width: 500px;
    margin: auto;
  }
}
