.admin-login-cont {
    height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)),url("../../Admin/Assets/log-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
}

.ad-log-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    background-color: transparent;
    backdrop-filter: blur(10px);
    padding: 2rem 1rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .ad-log-container .form-group {
    margin-bottom: 20px;
  }
  
  .ad-log-container label {
    color: wheat;
    font-size: 20px;
    font-weight: 500;
  }
  
  .ad-log-container input[type="text"],
  .ad-log-container input[type="password"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .ad-log-container input[type="text"]:focus,
  .ad-log-container input[type="password"]:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .ad-log-container button[type="submit"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .ad-log-container button[type="submit"]:hover {
    background-color: #0069d9;
  }
  
  .ad-log-container button[type="submit"]:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  