.h3 {
  margin-bottom: 10%;
}

.heart-icn {
  margin-left: 3rem;
  margin-top: 30px;
  font-size: 3rem;
  color: red;
  animation: jump 0.5s infinite;
}

@keyframes jump {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, -60%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.pink-line {
  height: 5px;
  background-color: rgb(229, 5, 42);
  border: none;
  width: 50%;
}

.pink-line1 {
  margin-left: 50%;
  height: 5px;
  background-color: rgb(236, 0, 39);
  border: none;
  width: 50%;
}

.client-rev-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.testimonial {
  padding: 20px;
  height: 390px;
  width: 350px;
  background-color: #fff;
  box-shadow: 0px 0px 5px #888888;

}
.testimonial p{
font-size: 14px;
text-align: justify;
}
.avatar {
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin: 0 auto;
}

.dark-grey-text {
  color: #757575;
}

.blue-text {
  color: #007bff;
}
