.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.images-container {
  position: relative;
  width: 700px;
  height: 200px;
}

.left-image,
.right-image {
  position: absolute;
  top: 0;
  width: 150px;
  height: 200px;
  transition: transform 3s ease;
}

.left-image {
 left: 0;
}

.right-image {
  right: 0;
}

.heart-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: red;
  animation: jump 0.5s infinite;
}

@keyframes jump {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, -60%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.loader {
  width: 800px;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-top: 20px;
}

.loader-progress {
  height: 100%;
  background-color: #007bff;
  border-radius: 5px;
  transition: width 1s ease;
}

.loader-percentage {
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
}
