.view-prof-container {
  display: flex;
  gap: 20px;
  background-color: #f5f5f5;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.view-prof-left,
.view-prof-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/v-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.view-prof-left-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.get-prof-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.view-prof-left-basic-info,
.view-prof-right-basic-info {
  line-height: 32px;
}

.view-prof-left-basic-info p,
.view-prof-right-basic-info p {
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.view-prof-left-basic-info p span,
.view-prof-right-basic-info p span {
  font-size: 1.2rem;
  font-weight: 501;
  text-transform: none;
  margin-left: 10px;
}

.view-prof-left,
.view-prof-right {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.vp-upgrade-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  margin: 1rem auto;
}

/* Modal background */
.preview-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(7px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal content */
.preview-modal-content {
  position: relative;
  width: 80vw;
  height: 80vh;
  /* overflow: auto; */
}

/* Close button */
.preview-close {
  position: absolute;
  top: 0px;
  right: -10px;
  font-size: 32px;
  cursor: pointer;
  color: #fff;
  z-index: 1;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Preview image */
.preview-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
  margin: 0;
  pointer-events: none;
}

/* Responsive design for smaller screens */
@media screen and (max-width: 768px) {
  .view-prof-container {
    flex-direction: column;
  }

  .view-prof-left,
  .view-prof-right {
    margin-bottom: 20px;
  }
  .view-prof-left-basic-info,
  .view-prof-right-basic-info {
    margin-right: auto;
  }
  .preview-modal-content {
    max-width: 95vw;
    max-height: 70vh;
  }

  .preview-close {
    top: -15px;
    right: -15px;
    font-size: 24px;
    padding: 4px 8px;
  }
}
