.name-filter {
  margin-bottom: 20px;
}

.name-filter input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}



.unverified-profiles-container {
    margin: 20px;
    margin-left: 100px;
    overflow: auto;
  }
  
  .unverified-profiles-heading {
    font-size: 36px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #e22b63;
    text-align: center;
    font-weight: 600;
  }
  
  .unverified-profiles-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .unverified-profiles-table th,
  .unverified-profiles-table td {
    text-align: center;
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ad2727;
  }
  
  .unverified-profiles-table th {
    font-weight: bold;
    background-color: #b63333;
    color: #ffffff;
    border: 1px solid #000000;
  }
  
  .unverified-profiles-table td {
    background-color: whitesmoke;
    border: 1px solid #000;
    border-top: 1px solid #ad2727;
  }
  
  .unverified-profiles-table td button {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    background-color: #b63333;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .unverified-profiles-table td button:hover {
    background-color: #991e1e;
  }
  
  .unverified-profiles-table tbody tr:hover {
    background-color: #f0f0f0;
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    border: none;
    background-color: #eaeaea;
    color: #333;
    padding: 8px 12px;
    margin: 0 4px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 20px;
  }
  
  .pagination button.active {
    background-color: #333;
    color: #fff;
  }
  